import "./LinkPageDropdown.css";
import { Link } from "react-router-dom";

interface ILinkPageDropdown {
  linkToPage: string;
  linkText: string;
  action?: () => void;
}

const LinkPageDropdown = ({
  linkToPage,
  linkText,
  action = () => {},
}: ILinkPageDropdown) => {
  const linkStyle = {
    color: "#13334d",
    textDecoration: "none",
    fontWeight: "bold",
  };
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <span
      className="link-page-dropdown"
      onClick={() => {
        scrollTop();
        action();
      }}
    >
      <Link
        className="custom-link-dropdown"
        to={linkToPage}
        property=""
        style={linkStyle}
      >
        {linkText}
      </Link>
    </span>
  );
};

export default LinkPageDropdown;
