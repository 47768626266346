import PictureSlider, {
  IPicture,
} from "../../../composit/pictureSlider/PictureSlider";
import "./ProjectGalerySection.css";

const builder1 = require("../../../assets/builder1.jpg");
const builder2 = require("../../../assets/builder2.jpg");
const builder3 = require("../../../assets/builder3.jpg");
const builder4 = require("../../../assets/builder4.jpg");
const builder5 = require("../../../assets/builder5.jpg");

const ProjectGalerySection = () => {
  const pictures: IPicture[] = [
    {
      name: "builder1",
      source: builder1,
    },
    {
      name: "builder2",
      source: builder2,
    },
    {
      name: "builder4",
      source: builder4,
    },
    {
      name: "builder3",
      source: builder3,
    },
    {
      name: "builder5",
      source: builder5,
    },
  ];
  return (
    <div>
      <div className="project-galery-section-container ">
        <div className="project-galery-section-container-inner sizer">
          <PictureSlider pictures={pictures} />
        </div>
      </div>
    </div>
  );
};
export default ProjectGalerySection;
