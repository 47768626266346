import { useState } from "react";
import "./PictureSlider.css";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export interface IPicture {
  name: string;
  source: string;
}
interface IPictureSlider {
  pictures: IPicture[];
}
const PictureSlider = ({ pictures }: IPictureSlider) => {
  const [firstPicture, ...restOfPictures] = pictures;
  const [currentPicture, setCurrentPicture] = useState<IPicture>();
  const [nonDisplayClass, setNonDisplayClass] = useState<string>("non-display");

  const selectPicture = (pic: IPicture) => {
    setCurrentPicture(pic);
    setNonDisplayClass("");
  };
  const deSelectPicture = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      event.target &&
      (event.target as HTMLElement).classList.contains("full-image-container")
    ) {
      setNonDisplayClass("non-display");
    }
  };
  const nextPicture = (direction: number) => {
    let index = 0;
    if (currentPicture) index = pictures.indexOf(currentPicture);

    index = index + direction;
    if (index < 0) {
      index = pictures.length - 1;
    } else if (index > pictures.length - 1) {
      index = 0;
    }
    setCurrentPicture(pictures[index]);
  };

  return (
    <div>
      <div className="picture-slider-container">
        <div className="picture-slider-container-inner">
          {firstPicture && (
            <div
              className="big-picture-section"
              onClick={() => selectPicture(firstPicture)}
            >
              <div className="big-picture-wraper">
                <img src={firstPicture.source} alt={firstPicture.name} />
                {/* <Slide>
                  <div>{firstPicture.name}</div>
                </Slide> */}
              </div>
            </div>
          )}
          {restOfPictures.length > 0 && (
            <div className="small-pictures-section">
              {restOfPictures.map((pic, index) => (
                <div
                  className="small-picture-wraper"
                  onClick={() => selectPicture(pic)}
                >
                  <img key={index} src={pic.source} alt={pic.name} />
                  {/* <Slide>
                    <div>{pic.name}</div>
                  </Slide> */}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div
        className={"full-image-container " + nonDisplayClass}
        onClick={deSelectPicture}
      >
        <div className="full-image-view">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="full-image-left"
            onClick={() => nextPicture(-1)}
          />
          <img src={currentPicture?.source} alt={currentPicture?.name} />
          <FontAwesomeIcon
            icon={faChevronRight}
            className="full-image-right"
            onClick={() => nextPicture(1)}
          />
        </div>
      </div>
    </div>
  );
};

export default PictureSlider;
