import HomeHeadBottomLogoSection from "../home/homeHeadSection/homeHeadBottomLogoSection/HomeHeadBottomLogoSection";
import ProjectGalerySection from "../home/projectGalerySection/ProjectGalerySection";
import RedirectionSection from "../home/redirectionSection/RedirectionSection";
import "./Services.css";
import TopService from "./topServices/TopServices";
import WhyUsServices from "./whyUsServices/WhyUsServices";

const Services = () => {
  return (
    <div className="">
      <TopService />

      <RedirectionSection />
      <WhyUsServices />
      <ProjectGalerySection />
      <div className="service-logo sizer">
        <h2>
          Renomirani brendovi čije materijale koristimo u izradi stolarije
        </h2>
        <div className="service-logo-section">
          <HomeHeadBottomLogoSection />
        </div>
      </div>
    </div>
  );
};
export default Services;
