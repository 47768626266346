import "./Offer.css";
import OfferHeader from "./offerHeader/OfferHeader";
import OfferQuestions from "./offerQuestions/OfferQuestions";

const Offer = () => {
  return (
    <div>
      <div className="">
        <div>
          <OfferHeader />
          <OfferQuestions />
        </div>
      </div>
    </div>
  );
};

export default Offer;
