import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import FakeLink from "../../../../common/link/fakeLink/FakeLink";
import "./ContactToperInfo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AMediaLink from "../../../../common/link/awesomeMediaLink/AwesomeMediaLink";
import Reveal from "../../../../utils/reveal/Reveal";
const ContactToperInfo = () => {
  return (
    <div>
      <div className="contact-toper-info-container">
        <div className="contact-toper-info-inner">
          <Reveal duration={0.5}>
            <h3 className="red-header">Kontakt</h3>
          </Reveal>
          <Reveal duration={0.5} delay={0.2}>
            <h1> Kontaktirajte nas</h1>
          </Reveal>

          <Reveal duration={1} delay={0.2}>
            <h5>
              Kontaktirajte nas putem mail-a, telefona ili zapratite naš rad na
              društvenim mrežama i budite informisani u svakom trenuku o
              novostima i dešavanjima. Za sva pitanja, servis, traženje ponude
              ili informisanja stojimo Vam na raspolaganju i tudićemo se da Vam
              izađemo u susret u najkraćem roku.
            </h5>
          </Reveal>

          <Reveal duration={1} delay={0.4}>
            <div className="mail-contact-container">
              <div className="contact-holder">
                <div className="icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div>
                  Email
                  <div>
                    <FakeLink
                      text="alustillns@gmail.com"
                      toAction="mailto:alustillns@gmail.com"
                    />
                  </div>
                </div>
              </div>
              <div className="contact-holder">
                <div className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div>
                  Telefon
                  <div>
                    <FakeLink
                      text="062 / 87 14 363"
                      toAction="tel:+381628714363"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Reveal>
          <Reveal duration={1.2} delay={0.4}>
            <div className="contact-social-media">
              <h2> Naše društvene mreže</h2>
              <div className="contact-social-media-inner">
                <AMediaLink icon={faFacebook} />
                <AMediaLink icon={faWhatsapp} />
              </div>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
};
export default ContactToperInfo;
