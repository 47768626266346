import "./Image.css";
interface IImage {
  source: string;
  altName: string;
}
const Image = ({ source, altName }: IImage) => {
  return (
    <span>
      <img src={source} alt={altName} />
    </span>
  );
};
export default Image;
