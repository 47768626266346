import Slider from "../../../composit/slider/Slider";
import Worker from "../../../composit/worker/Worker";
import { SiteVars } from "../../../utils/SiteVars";
import "./OurTeam.css";
const OurTeam = () => {
  const sliders = SiteVars.aboutPage.workers.map((x) => <Worker prop={x} />);
  return (
    <div className="our-team">
      <div className="our-team-container">
        <div className="our-team-container-inner sizer">
          <div className="our-team-text">
            <h5 className="red-header">Naš tim</h5>
            <h1> Naš tim profesionalaca</h1>
          </div>
          <div className="worker-carousel">
            <Slider prop={{ sliders }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurTeam;
