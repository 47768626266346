import { useNavigate } from "react-router-dom";
import Button from "../../../../common/button/Button";
import { SiteVars } from "../../../../utils/SiteVars";
import "./HomeHeadTextSection.css";

const HomeHeadTextSection = () => {
  const navigate = useNavigate();

  const changeUrl = () => {
    // Use the navigate function to navigate to a new URL
    navigate("/offer");
    window.scrollTo(0, 0);
  };

  const { title, subTitle, buttonName } = SiteVars.homehead;
  return (
    <div className="home-head-text-section">
      <h1>{title}</h1>
      <h5>{subTitle}</h5>
      <Button name={buttonName} action={() => changeUrl()} />
    </div>
  );
};

export default HomeHeadTextSection;
