import "./HeadImage.css";

interface IHeadImage {
  source: string;
  altName: string;
}
const HeadImage = ({ source, altName }: IHeadImage) => {
  return (
    <span>
      <img className="head-img" src={source} alt={altName} />
    </span>
  );
};
export default HeadImage;
