import ArticlesSection from "./articlesSection/ArticlesSection";
import CounterSection from "./counterHomeSection/CounterHomeSection";
import HomeHeadSection from "./homeHeadSection/HomeHeadSection";
import "./HomePage.css";
import OurProcessSection from "./ourProcessSection/OurProcessSection";
import OurServicesSection from "./ourServicesSection/OurServicesSection";
import ProjectGalerySection from "./projectGalerySection/ProjectGalerySection";
import RedirectionSection from "./redirectionSection/RedirectionSection";
import WhatSaySection from "./whatSaySection/WhatSaySection";
import WhyUsSection from "./whyUsSection/WhyUsSection";

const HomePage = () => {
  return (
    <div className="container ">
      <HomeHeadSection />
      <OurServicesSection />
      <WhyUsSection />
      <ProjectGalerySection />
      <OurProcessSection />
      <WhatSaySection />
      <ArticlesSection />
      <CounterSection />
      <RedirectionSection />
    </div>
  );
};

export default HomePage;
