import Image from "../../../../common/image/Image";
import CounterSection from "../../../../composit/counterSection/CounterSection";
import "./OurServicesPictureSection.css";
const bulder = require("./../../../../assets/builder2-cut.jpg");

const OurServicesPictureSection = () => {
  return (
    <div>
      <div className="our-services-container">
        <div className="img-wraper-our-services-container">
          <Image altName="worker" source={bulder} />
        </div>
        <div className="our-services-counter-section">
          <CounterSection num={20} text="Godina" />
        </div>
      </div>
    </div>
  );
};

export default OurServicesPictureSection;
