import { Link } from "react-router-dom";
import "./QuickPageLink.css";
interface IQuickPageLink {
  linkToPage: string;
  linkText: string;
}
const QuickPageLink = ({ linkToPage, linkText }: IQuickPageLink) => {
  const linkStyle = {
    textDecoration: "none",
    fontWeight: "bold",
  };
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <span className="quick-link" onClick={scrollTop}>
      <Link
        className="quick-custom-link"
        to={linkToPage}
        property=""
        style={linkStyle}
      >
        {linkText}
      </Link>
    </span>
  );
};
export default QuickPageLink;
