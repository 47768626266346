import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import "./AwesomeMediaLink.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IAMediaLink {
  icon: IconDefinition;
  showWraper?: boolean;
}

const AMediaLink = ({ icon, showWraper = false }: IAMediaLink) => {
  return (
    <div className="a-media-icon">
      <div className="a-media-icon-inner">
        <FontAwesomeIcon icon={icon} />
      </div>
    </div>
  );
};

export default AMediaLink;
