import "./Button.css";

interface IButton {
  name: string;
  action: () => void;
  isInverted?: boolean;
}
const Button = ({ name, action, isInverted = false }: IButton) => {
  const inverted = isInverted ? "btn-inverted" : "";
  return (
    <div>
      <button className={`btn ${inverted}`} onClick={action}>
        {name}
      </button>
    </div>
  );
};
export default Button;
