import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import "./MediaLink.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IMediaLink {
  icon: IconDefinition;
  showWraper?: boolean;
}

const MediaLink = ({ icon, showWraper = false }: IMediaLink) => {
  const showWraperStyle: string = showWraper ? "media-wrap" : "";
  return (
    <span className="media-icon">
      <span className={showWraperStyle}>
        <FontAwesomeIcon icon={icon} />
      </span>
    </span>
  );
};

export default MediaLink;
