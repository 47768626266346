import QuickPageLink from "../../../common/link/quickPageLink/QuickPageLink";
import navItems from "../../../utils/NavItems";
import "./QuickLinkSection.css";

const QuickLinkSection = () => {
  return (
    <div>
      <h3>Quick Links</h3>

      <div className="quick-link-list">
        {navItems.map((x, index) => (
          <QuickPageLink key={index} linkText={x.name} linkToPage={x.path} />
        ))}
      </div>
    </div>
  );
};
export default QuickLinkSection;
