import Logo from "../../../../common/logo/Logo";
import { SiteVars } from "../../../../utils/SiteVars";
import Reveal from "../../../../utils/reveal/Reveal";
import "./HomeHeadBottomLogoSection.css";

const HomeHeadBottomLogoSection = () => {
  const { logoList } = SiteVars.homehead;
  return (
    <Reveal>
      <>
        <div className="home-head-bottom-logo-section">
          {logoList.map((x, index) => (
            <Logo key={index} imageName={x.logoName} imagePath={x.logoSrc} />
          ))}
        </div>
      </>
    </Reveal>
  );
};
export default HomeHeadBottomLogoSection;
