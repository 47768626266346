import "./ContactToper.css";
import ContactToperForm from "./contactToperForm/ContactToperForm";
import ContactToperInfo from "./contactToperInfo/ContactToperInfo";
const ContactToper = () => {
  return (
    <div className="contact-toper">
      <div className="contact-toper-container">
        <div className="contact-toper-inner">
          <ContactToperInfo />
          <ContactToperForm />
        </div>
      </div>
    </div>
  );
};
export default ContactToper;
