import "./CounterWithPicture.css";
import IImage from "../../utils/interface/IImage";
import NumberUp from "../../utils/numberUp/NumberUp";
interface ICounterWithPicture extends IImage {
  countingNumber: number;
  title: string;
}
const CounterWithPicture = ({
  imgSrc,
  countingNumber,
  title,
}: ICounterWithPicture) => {
  return (
    <div>
      <div className="counter-with-picture-container">
        <img src={imgSrc} alt="" />
        <h1>
          <NumberUp number={countingNumber} />
          <span className="plus">+</span>
        </h1>
        <h5>{title}</h5>
      </div>
    </div>
  );
};
export default CounterWithPicture;
