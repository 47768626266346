import "./TopHead.css";
import TopInfoSection from "./topInfoSection/topInfoSection";
import TopMediaSection from "./topMediaSection/TopMediaSection";
const TopHead = () => {
  return (
    <div className="top-head ">
      <div className="inner-top-head-container sizer">
        <TopInfoSection />
        <TopMediaSection />
      </div>
    </div>
  );
};
export default TopHead;
