import OurProcessTextSection from "./OurProcessHeadTextSection/OurProcessTextSection";
import "./OurProcessSection.css";
import StepSection from "./stepSection/StepSection";

const OurProcessSection = () => {
  return (
    <div>
      <div className="our-process-section-container">
        <div className="our-process-section-container-inner sizer">
          <OurProcessTextSection />
          <StepSection />
        </div>
      </div>
    </div>
  );
};
export default OurProcessSection;
