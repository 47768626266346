import "./HomeHeadSection.css";
import HomeHeadBottomLogoSection from "./homeHeadBottomLogoSection/HomeHeadBottomLogoSection";
import HomeHeadPictureSection from "./homeHeadPictureSection/HomeHeadPictureSection";
import HomeHeadTextSection from "./homeHeadTextSection/HomeHeadTextSection";

const HomeHeadSection = () => {
  return (
    <div>
      <div className="home-head-background">
        <div className="home-head-container sizer">
          <div className="home-head-section left">
            <HomeHeadTextSection />
          </div>
          <div className="home-head-section right">
            <HomeHeadPictureSection />
          </div>
        </div>
      </div>
      <div className="home-head-bottom sizer">
        <HomeHeadBottomLogoSection />
      </div>
    </div>
  );
};
export default HomeHeadSection;
