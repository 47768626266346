import { Link, NavLinkProps } from "react-router-dom";
import "./LinkPage.css";

interface ILinkPage {
  linkToPage: string;
  linkText: string;
}

const LinkPage = ({ linkToPage, linkText }: ILinkPage) => {
  const linkStyle = {
    textDecoration: "none",
    fontWeight: "bold",
  };

  return (
    <span className="link-page">
      <Link
        className="custom-link"
        to={linkToPage}
        property=""
        style={linkStyle}
      >
        {linkText}
      </Link>
    </span>
  );
};

export default LinkPage;
