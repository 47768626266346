import { AboutVar, IAboutVar } from "./variables/aboutVariables/AboutVar";
import {
  FirstLocationVar,
  ILocationVar,
  SecondLocationVar,
} from "./variables/contactVariables/LocationVar";
import {
  ArticleVar,
  IArticleVar,
} from "./variables/homeVariables/article/ArticleVar";
import {
  HomeHeadVar,
  IHomeHeadVar,
} from "./variables/homeVariables/homeHead/HomeHeadVar";

interface ISiteVars {
  homehead: IHomeHeadVar;
  articlePage: IArticleVar;
  firstLocation: ILocationVar;
  secondLocation: ILocationVar;
  aboutPage: IAboutVar;
}
export const SiteVars: ISiteVars = {
  homehead: HomeHeadVar,
  articlePage: ArticleVar,
  firstLocation: FirstLocationVar,
  secondLocation: SecondLocationVar,
  aboutPage: AboutVar,
};
