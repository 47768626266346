import CounterSection from "../../../composit/counterSection/CounterSection";
import TextPoper from "../../../composit/textPoper/TextPoper";
import "./OfferQuestions.css";

const builder8 = require("../../../assets/builder8.jpg");

const OfferQuestions = () => {
  const textItems = [
    {
      title: "Gde mogu pronaći više informacija o prozorima?",
      text: "Za sva dodatna pitanja i nedoumice možete nas kontaktirati putem telefona 062/8714363 ili e mail poruke na alustillns@gmail.com i neko iz našeg tima će Vam odgovoriti u najkraćem roku.",
    },
    {
      title: "Kako funkcioniše plaćanje?",
      text: "Nakon potpisanog ugovora u kome su precizno utvrđeni obim radova, vrsta stolarije i celokupan iznos projekta sledi uplata avansa u iznosu od 70 procenata vrednosti ugovora, dok preostalik 30 procenata uplaćuje prilikom primoredaje objekta.",
    },
    {
      title: "Koji su uslovi za izradu stolarije?",
      text: "Nakon potpisanog ugovora i uplaćenog avansa sve što preostaje kupcu jeste da omogući pristup objektu i otvorima na kojima se menja stolarija.",
    },
    {
      title: "Zašto mi se javlja kondenz na prozorima i zidovima?",
      text: "Vaša stolarija je dobar izolator i odlično dihtuje, tako da nakupljen vlažan vazduh nema gde da ode i kondenzuje se na prozorima i zidovima. Ovo je normalan proces i ne treba da Vas brine. Vlažan vazduh nastaje usled disanja u prostoriji, kuvanja upotreba tuša i toaleta, a sve što Vi treba da uradite jeste da povremeno provetrite prostoriju ili malo odšktinete prozor kako bi došlo do razmene i opadanja vlažnosti vazduha u prostoriji.",
    },
  ];
  return (
    <div className="offer-questions-container ">
      <div className="offer-questions-inner sizer">
        <div className="who-are-we-left">
          <div className="who-are-we-image">
            <img src={builder8} alt="builder" />
            <div className="who-are-we-counter">
              <CounterSection
                text="završenih projekata:"
                alignText={true}
                num={800}
              />
            </div>
          </div>
        </div>
        <div className="who-are-we-right">
          <h5 className="red-header"> saznaj više</h5>
          <h1>Najčešća pitanja</h1>
          <p>pitanja sa kojima se najčešće susrećemo u radu sa našim kupcima</p>
          <TextPoper items={textItems} />
        </div>
      </div>
    </div>
  );
};
export default OfferQuestions;
