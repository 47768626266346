import Apear from "../../../../utils/apear/Apear";
import "./StepSection.css";
import Step from "./step/Step";

const StepSection = () => {
  return (
    <div className="step-section-container">
      <div className="step-section-left">
        <Apear>
          <Step
            orderNumber="01"
            title="Ispravljanje ponude i sklapanje ugovora"
            text="Telefonskim pozivom ili putem mail-a nam objasnite vašepotrebe vezane za stolariju kao i okvirne mere na osnovu čega se pravi ponuda i dalje sklapa ugovor."
          />
        </Apear>
        <Apear>
          <Step
            orderNumber="03"
            title="Izrada stolarije"
            text="Na osnovu precizno skinutih mera izrađuje se stolarija za vaš objekat."
            isInverted={true}
          />
        </Apear>
      </div>
      <div className="step-section-right">
        <Apear>
          <Step
            orderNumber="02"
            title="Precizno skidanje mera"
            text="Nakon sklapanja ugovora izlazi se na teren i skidaju se precizne mere stolarije."
          />
        </Apear>
        <Apear>
          <Step
            orderNumber="04"
            title="Ugradnja stolarije"
            text="Poslednja etapa procesa je sama ugradnja stolarije i po potrebi demontaža stare."
          />
        </Apear>
      </div>
    </div>
  );
};
export default StepSection;
