import { useState } from "react";
import Expand from "../../utils/expand/Expand";
import "./Article.css";
import ModalQuestion from "../modalQuestion/ModalQuestion";

interface IArticle {
  imgSource: string;
  imgName: string;
  tittle: string;
  date: string;
  text: string;
}

const Article = ({ imgSource, imgName, tittle, date, text }: IArticle) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="article-container">
      <div className="article-container-inner">
        <img src={imgSource} alt={imgName} />
        <h3 onClick={toggle}>{tittle}</h3>
        <span className="read-more" onClick={toggle}>
          <ModalQuestion
            text={text}
            question=""
            title=" Saznajte više  >>"
            arrow={false}
          />
        </span>
        {/* <Expand open={isOpen}>
          <div className={isOpen ? "opend-text" : "closed-text"}>{text}</div>
        </Expand> */}
      </div>
    </div>
  );
};
export default Article;
