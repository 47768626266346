import CounterWithPicture from "../../../composit/counterWithPicture/CounterWithPicture";
import "./CounterHomeSection.css";
const people = require("../../../assets/people.png");
const grid = require("../../../assets/grid-2.png");
const like = require("../../../assets/like-shapes-02.png");

const CounterSection = () => {
  return (
    <div className="counter-home-section-container sizer">
      <div className="counter-home-section-container-inner">
        <CounterWithPicture
          countingNumber={6}
          imgName="brojRadnika"
          imgSrc={people}
          title="Naš tim profesionalaca"
        />
        <span className="separater">{""}</span>
        <CounterWithPicture
          countingNumber={800}
          imgName="brojRadnika"
          imgSrc={grid}
          title="Izvedenih projekata"
        />
        <span className="separater">{""}</span>
        <CounterWithPicture
          countingNumber={560}
          imgName="brojRadnika"
          imgSrc={like}
          title="Zadovoljnih kupaca"
        />
      </div>
    </div>
  );
};

export default CounterSection;
