import IImage from "../../utils/interface/IImage";
import { IProp } from "../../utils/interface/IProp";
import "./Worker.css";
export interface IWorker extends IImage {
  name: string;
  role: string;
}
const Worker = ({ prop }: IProp<IWorker>) => {
  const { name, role, imgSrc, imgName } = prop;
  return (
    <div className="worker">
      <div className="worker-container">
        <div className="worker-container-inner">
          <div className="worker-img">
            <img src={imgSrc} alt={imgName} />
          </div>
          <span className="worker-line"></span>
          <h4>{name}</h4>
          <h5>{role}</h5>
        </div>
      </div>
    </div>
  );
};
export default Worker;
