import { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Expand.css";

interface IExpand {
  children: JSX.Element;
  open: boolean;
}
const Expand = ({ children, open }: IExpand) => {
  const ref = useRef(null);
  const menVars = {
    initial: {
      scaleY: 0,
      zIndex: 1,
    },
    animate: {
      scaleY: 1,
      zIndex: 1,
    },
    exit: {
      scaleY: 0,
      zIndex: 1,
    },
  };
  return (
    <div ref={ref} className="expand-toper origin-top">
      <AnimatePresence>
        {open && (
          <motion.div
            variants={menVars}
            initial={"initial"}
            animate={"animate"}
            exit={"exit"}
            transition={{ duration: 0.3 }}
            style={{ position: "relative" }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default Expand;
