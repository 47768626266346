import HomeHeadBottomLogoSection from "../home/homeHeadSection/homeHeadBottomLogoSection/HomeHeadBottomLogoSection";
import AboutMeTopper from "./aboutMeTopper/AboutMeTopper";
import "./AboutMe.css";
import AboutMeOrigin from "./aboutMeOrigin/AboutMeOrigin";
import WhoAreWe from "./whoAreWe/WhoAreWe";
import WhyUsSection from "../home/whyUsSection/WhyUsSection";
import RedirectionSection from "../home/redirectionSection/RedirectionSection";
import OurTeam from "./ourTeam/OurTeam";

const AboutMe = () => {
  return (
    <div className="about-me">
      <div className="about-me-container">
        <div className="about-me-container-inner  ">
          <AboutMeTopper />
          <div className="about-me-logo-section sizer">
            <HomeHeadBottomLogoSection />
          </div>
          <AboutMeOrigin />
          <WhoAreWe />
          <WhyUsSection />
          <OurTeam />
          <RedirectionSection />
        </div>
      </div>
    </div>
  );
};
export default AboutMe;
