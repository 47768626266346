import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ServicePresentation from "../../../composit/servicePresentation/ServicePresentation";
import "./TopService.css";
import Reveal from "../../../utils/reveal/Reveal";

const drill = require("../../../assets/drill.png");
const windowPicture = require("../../../assets/window.png");
const door = require("../../../assets/door.png");
const doorKnob = require("../../../assets/doorknob.png");
const checkImg = require("../../../assets/check.png");

const TopService = () => {
  return (
    <div>
      <div className="service-bacground">{""}</div>
      <div className="service-container sizer">
        <div className="service-container-inner">
          <h1>Usluge</h1>
          <h5>
            Vršimo usluge izrade, montaže i servisiranje aluminijumske i PVC
            stolarije, kao i demontažu postojeće.
          </h5>
          <div className="service-presentation-outer">
            <Reveal>
              <div className="service-presentation-outer-wraper">
                <ServicePresentation
                  imgName="windowPicture"
                  imgSrc={windowPicture}
                  text="Stolarija izrađena od Salamender PVC profila, Sigenia okvirom i italijanskim staklom punjenim argonom."
                  title="PVC Stolarija"
                />
                <div className="service-presentation-checker">
                  <ServicePresentation
                    faIco={faCircleCheck}
                    title=""
                    text="Skidanje mera i savetovanje ulazi u cenu stolarije"
                  />
                  <ServicePresentation
                    faIco={faCircleCheck}
                    title=""
                    text="Konsultacije i izrade ponuda se ne naplaćuju, izlazak na teren se naplaćuje"
                  />
                </div>
              </div>
            </Reveal>
            <Reveal>
              <div className="service-presentation-outer-wraper">
                <ServicePresentation
                  imgName="drill"
                  imgSrc={drill}
                  text="Naši kupci su osigurani uslugama servisiranja stolarije kao i savetovanjem vezano za izbor, upotrebu i prateće tehnikalije."
                  title="Servisiranje i savetovanje"
                />
                <div className="service-presentation-checker">
                  <ServicePresentation
                    faIco={faCircleCheck}
                    title=""
                    text="Dugogodšnje postojanje nam dopušta da kupcima garantujemo sigurnu kupovinu i potrebnu podršku"
                  />
                  <ServicePresentation
                    faIco={faCircleCheck}
                    title=""
                    text="U slučaju oštećenja, kvarova ili potrebe za redovnim servisiranjem nastalog dugogodišnjom upotrebom stojimo Vam na raspolaganju"
                  />
                </div>
              </div>
            </Reveal>

            <Reveal delay={0.5}>
              <div className="service-presentation-outer-wraper">
                <ServicePresentation
                  imgName="door"
                  imgSrc={door}
                  text="Stolarija izrađena od Alumili i Elvial aluminijumskih profila, Siegenia okvirom i italijanskim staklom punjenim argonom."
                  title="Aluminijumska stolarija"
                />
                <div className="service-presentation-checker">
                  <ServicePresentation
                    faIco={faCircleCheck}
                    title=""
                    text="Skidanje mera i savetovanje ulazi u cenu stolarije"
                  />
                  <ServicePresentation
                    faIco={faCircleCheck}
                    title=""
                    text="Konsultacije i izrade ponuda se ne naplaćuju, izlazak na teren se naplaćuje"
                  />
                </div>
              </div>
            </Reveal>

            <Reveal delay={0.5}>
              <div className="service-presentation-outer-wraper">
                <ServicePresentation
                  imgName="doorKnob"
                  imgSrc={doorKnob}
                  text="Nudimo Vam mogućnost izrade i montaže komarnika, solomatika, roletni, klupica, okapnica i ostale prateće opreme za Vašu stolariju."
                  title="Prateća oprema"
                />
                <div className="service-presentation-checker">
                  <ServicePresentation
                    faIco={faCircleCheck}
                    title=""
                    text="Skidanje mera i ugradnja prateće opreme je uračunata u cenu"
                  />
                  <ServicePresentation
                    faIco={faCircleCheck}
                    title=""
                    text="Konsultacije i izrade ponuda se ne naplaćuju, izlazak na teren se naplaćuje"
                  />
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopService;
