export interface IHomeHeadVar {
  title: string;
  subTitle: string;
  buttonName: string;
  buttonRedirect: string;
  headComment: string;
  counterNumber: number;
  counterTitle: string;
  headImg: string;
  topImg: string;
  topImgAltName: string;
  logoList: { logoName: string; logoSrc: string }[];
}
export const HomeHeadVar: IHomeHeadVar = {
  title: "Aluminijum i PVC stolarija vrhunskog kvaliteta",
  subTitle:
    "Zamenite stolariju kod nas i uživajte u toplini svog doma bez dodatnih troškova i odlaganja termina.",
  buttonName: "ŽELIM PONUDU",
  buttonRedirect: "",
  headComment: `"Brza usluga, kvalitetna stolarija."`,
  counterNumber: 2500,
  counterTitle: "Reviews",
  headImg: require("../../../../assets/Tim-koji-postuje-Vase-vreme-i-novac.jpeg"),
  topImg: require("./../../../../assets/builder1-cut.jpg"),
  topImgAltName: "worker",
  logoList: [
    {
      logoName: "Alumill",
      logoSrc: require("../../../../assets/alumil-aluminium.png"),
    },
    { logoName: "Elval", logoSrc: require("../../../../assets/elvial.png") },
    {
      logoName: "Salamander",
      logoSrc: require("../../../../assets/slamander.png"),
    },
    {
      logoName: "Siegena",
      logoSrc: require("../../../../assets/SIEGENIA_Logo_2022.svg.png"),
    },
  ],
};
