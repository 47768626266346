import {
  faClock,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import FakeLink from "../../../common/link/fakeLink/FakeLink";
import "./ContactInfoSection.css";

const ContactInfoSection = () => {
  return (
    <div>
      <h3> Kontakt informacije</h3>
      <div className="contact-info-footer">
        <FakeLink text="Pon-Pet : 08 - 16 h" favIco={faClock}></FakeLink>
        <FakeLink
          text="062 / 87 14 363"
          toAction="tel:+381628714363"
          favIco={faPhone}
        ></FakeLink>
        <FakeLink
          text="alustillns@gmail.com"
          toAction="mailto:alustillns@gmail.com"
          favIco={faEnvelope}
        ></FakeLink>
      </div>
    </div>
  );
};
export default ContactInfoSection;
