import { useEffect, useRef, useState } from "react";
import {
  motion,
  useInView,
  useAnimation,
  useMotionValue,
  useTransform,
  animate,
} from "framer-motion";

interface INumberUp {
  number: number;
}
const NumberUp = ({ number }: INumberUp) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));

  useEffect(() => {
    if (isInView) {
      const controls = animate(count, number, { duration: 1.5 });
      mainControls.start(controls);
      return controls.stop;
    }
  }, [isInView]);
  return (
    <span ref={ref}>
      <motion.span animate={mainControls}>{rounded}</motion.span>
    </span>
  );
};
export default NumberUp;
