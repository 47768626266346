import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

interface IReveal {
  children: JSX.Element;
  delay?: number;
  duration?: number;
}
const Reveal = ({ children, duration = 1, delay = 0.25 }: IReveal) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div ref={ref}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: duration, delay: delay }}
      >
        {children}
      </motion.div>
    </div>
  );
};
export default Reveal;
