import FakeLink from "../../../common/link/fakeLink/FakeLink";
import { IProp } from "../../../utils/interface/IProp";
import Reveal from "../../../utils/reveal/Reveal";
import "./OurOffice.css";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
interface IOurOffice {
  img: string;
  preTitle: string;
  title: string;
  text: string;
  adress: string;
  email: string;
  phoneNumber: string;
  location: string;
}
const OurOffice = ({ prop }: IProp<IOurOffice>) => {
  const { img, preTitle, title, text, adress, email, phoneNumber, location } =
    prop;
  return (
    <div className="our-office-toper">
      <div className="our-office-toper-container  sizer">
        <div className="our-office-toper-inner">
          <div className="our-office-toper-image">
            <img src={img} alt="apartment" />
          </div>
          <div className="our-office-toper-text">
            <Reveal duration={0.5}>
              <h3 className="red-header">{preTitle}</h3>
            </Reveal>
            <Reveal duration={0.5} delay={0.2}>
              <h1>{title}</h1>
            </Reveal>
            <Reveal duration={1} delay={0.2}>
              <h5>{text}</h5>
            </Reveal>
            <Reveal duration={0.5}>
              <div className="our-office-indent">
                <h3 className="">{adress}</h3>

                <FakeLink text={email} favIco={faEnvelope}></FakeLink>
                <FakeLink text={phoneNumber} favIco={faPhone}></FakeLink>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div>
        <div className="map">
          <iframe
            src={location}
            width="100%"
            height="100%"
            loading="lazy"
            // referrerpolicy="no-referrer-when-downgrade"
            // allowFullScreen="true"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default OurOffice;
