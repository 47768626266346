import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

interface IApear {
  children: JSX.Element;
  delay?: number;
  duration?: number;
}
const Apear = ({ children, duration = 1, delay = 0 }: IApear) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start({ scale: 1 });
    }
  }, [isInView]);
  return (
    <div ref={ref}>
      <motion.div
        initial={{ scale: 0 }}
        animate={mainControls}
        transition={{ duration: duration, delay: delay }}
      >
        {children}
      </motion.div>
    </div>
  );
};
export default Apear;
