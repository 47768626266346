import { useEffect, useRef } from "react";
import "./CounterSection.css";
import Reveal from "../../utils/reveal/Reveal";
import NumberUp from "../../utils/numberUp/NumberUp";

interface ICounterSection {
  num?: number;
  subtext?: string;
  text?: string;
  alignText?: boolean;
}
const CounterSection = ({
  num,
  text,
  subtext,
  alignText = false,
}: ICounterSection) => {
  return (
    <Reveal>
      <div>
        <div className="counter-section-container anime-to-top">
          <div className="top-box">
            <div className={`top-box-inner ${alignText ? "align-left" : ""}`}>
              {num && (
                <h3>
                  <NumberUp number={num} /> <span> +</span>
                </h3>
              )}
              <h5> {text}</h5>
              <h5> {subtext}</h5>
            </div>
          </div>
          <div className="bottom-box"></div>
        </div>
      </div>
    </Reveal>
  );
};
export default CounterSection;
