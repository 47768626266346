import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkPage from "../../../common/link/linkPage/LinkPage";
import Logo from "../../../common/logo/Logo";
import navItems from "../../../utils/NavItems";
import "./Navigation.css";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Expand from "../../../utils/expand/Expand";
import LinkPageDropdown from "../../../common/link/linkPage/linkPageDropdown/LinkPageDropdown";
import { useLocation } from "react-router-dom";
const logo = require("../../../assets/Logo.png");

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("white-back");

  const location = useLocation();
  const currentPath = location.pathname;

  const open = () => {
    console.log(`${isOpen}`);
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    switch (currentPath) {
      case "/contact":
        setBackgroundColor("blue-back");
        break;
      case "/services":
        setBackgroundColor("gray-back");
        break;

      default:
        setBackgroundColor("white-back");
    }
    console.log(currentPath);
  }, [location]);
  return (
    <div className={"nav-container " + backgroundColor}>
      <div className="nav-container-inner sizer">
        <div className="logo-wrap">
          <Logo imageName="Alu-Still" imagePath={logo} />
        </div>
        <div className="nav-menu">
          {navItems.map((x, index) => (
            <LinkPage key={index} linkText={x.name} linkToPage={x.path} />
          ))}
        </div>
        <div className="nav-dropdown-button">
          <div className="bars" onClick={open}>
            {isOpen ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
          </div>
        </div>
      </div>
      <Expand open={!isOpen}>
        <div className="dropdown-container">
          <div className={!isOpen ? "opend-dropdown" : "closed-dropdown"}>
            <div className="dropdown-container-inner">
              <div className="dropdown-nav-menu">
                {navItems.map((x, index) => (
                  <div className="dropdown-nav-menu-item">
                    <LinkPageDropdown
                      key={index}
                      linkText={x.name}
                      linkToPage={x.path}
                      action={open}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Expand>
    </div>
  );
};
export default Navigation;
