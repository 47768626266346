import { useState } from "react";
import "./ModalQuestion.css";
interface IModalQuestion {
  title: string;
  question: string;
  text: string;
  arrow?: boolean;
}
const ModalQuestion = ({
  title,
  question,
  text,
  arrow = true,
}: IModalQuestion) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="text-poper-item-title" onClick={toggleModal}>
        {title}
        <span className="arrow-right">{arrow && ` >`}</span>
      </div>
      {isOpen && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <h2>{question}</h2>

            <span></span>
            <p>{text}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalQuestion;
