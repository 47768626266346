export interface ILocationVar {
  img: string;
  preTitle: string;
  title: string;
  text: string;
  adress: string;
  email: string;
  phoneNumber: string;
  location: string;
}
const imgReq = require("../../../assets/apartment.jpg");
export const FirstLocationVar: ILocationVar = {
  img: imgReq,
  preTitle: "Lokacija 1 ",
  title: "Proizvodnja",
  text: "Posetite ili pozovite našu proizvodnju i saznajte sve što Vas interesuje Vezano za stolariju.",
  adress: "Slobodana Macure 1, Sombor",
  email: "alustillns@gmail.com",
  phoneNumber: "063 / 104 11 38",
  location:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11130.308585855288!2d19.083808787158205!3d45.779664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475cca777bc558eb%3A0x98c8bdf5b560836!2sEtno-Stil%20025!5e0!3m2!1sen!2srs!4v1700426847375!5m2!1sen!2srs",
};

export const SecondLocationVar: ILocationVar = {
  img: imgReq,
  preTitle: "Lokacija 2 ",
  title: "Prodaja i podrška",
  text: "Slobodno nas kontaktirajte i saznajte sve što Vas interesuje vezano za stolariju.",
  adress: "Dragiše Brašovana 14, Novi Sad",
  email: "alustillns@gmail.com",
  phoneNumber: "063 / 87 14 363",
  location:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2809.3688114589822!2d19.842866576632062!3d45.240334348347076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b100e2aa58a0b%3A0x8afa10d5214ddfb5!2sDragi%C5%A1e%20Bra%C5%A1ovana%2014%2C%20Novi%20Sad!5e0!3m2!1sen!2srs!4v1700426327206!5m2!1sen!2srs",
};
