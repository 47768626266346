import Reveal from "../../../utils/reveal/Reveal";
import "./WhatSaySection.css";
const couplePicture = require("../../../assets/couple.png");
const quotation = require("../../../assets/quotation.png");
const WhatSaySection = () => {
  return (
    <div className="what-say-section-container ">
      <div className="what-say-section-container-inner sizer">
        <div>
          <Reveal>
            <h1>Šta naši kupci misle</h1>
          </Reveal>
        </div>
        <div>
          <Reveal>
            <div className="customer-comment-section">
              <span className="couple">
                <img src={couplePicture} alt="couple" />
              </span>
              <div className="customer-comment-text-section">
                <img src={quotation} alt="quotation" />
                <p>“Brza usluga, kvalitetna stolarija.”</p>
                <h5>Kristina Nestorović</h5>
                <span>Zadovoljni klijent</span>
              </div>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
};
export default WhatSaySection;
