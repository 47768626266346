import "./AboutMeOrigin.css";

const AboutMeOrigin = () => {
  return (
    <div className="about-me-origin">
      <div className="about-me-origin-container">
        <div className="about-me-origin-container-inner sizer">
          <div className="about-me-origin-left">
            <h5 className="red-header"> O nama</h5>
            <h1>Kako je nastao Alu-still</h1>
          </div>
          <div className="about-me-origin-right">
            <h5>
              Alu-still je porodična kompanija osnovana 2004. godine na bazi
              višedecenijskog iskustva i rada sa aluminijumskom, a potom i pvc
              stolarijom od njenih samih pocetaka.
            </h5>
            <h5>
              Preko 20 godina postojimo na tržištu radeći po preporukama naših
              kupaca kako jednoporodične objekte, tako i stambene, sportske,
              industrijske i druge vrste objekata.
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutMeOrigin;
