import { useEffect } from "react";
import HeadImage from "../../../../../common/image/headImage/HeadImage";
import "./HeadWithComment.css";
import Reveal from "../../../../../utils/reveal/Reveal";

interface IHeadWithComment {
  img: string;
  comment: string;
}

const HeadWithComment = ({ img, comment }: IHeadWithComment) => {
  return (
    <Reveal>
      <div className="head-comment-container">
        <div className="inner-head-comment-container anime-to-top">
          <div>
            <HeadImage altName="girl 1 " source={img} />
          </div>
          <span>{comment}</span>
        </div>
      </div>
    </Reveal>
  );
};
export default HeadWithComment;
