import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FakeLink.css";

import { IconDefinition, faClock } from "@fortawesome/free-solid-svg-icons";
interface IFakeLink {
  text?: string;
  toAction?: string;
  favIco?: IconDefinition;
}
const FakeLink = ({ text, favIco, toAction }: IFakeLink) => {
  return (
    <a className="fake-link" href={toAction}>
      {favIco && <FontAwesomeIcon icon={favIco} />} {text}
    </a>
  );
};
export default FakeLink;
