import "./OurServicesSection.css";
import OurServicesPictureSection from "./ourServicesPictureSection/OurServicesPictureSection";
import OurServicesTextSection from "./ourServicesTextSection/OurServicesTextSection";

const OurServicesSection = () => {
  return (
    <div>
      <div className="our-services-section-container sizer">
        <div className="our-services-section-container-inner">
          <OurServicesPictureSection />
          <OurServicesTextSection />
        </div>
      </div>
    </div>
  );
};
export default OurServicesSection;
