import FakeLink from "../../../../common/link/fakeLink/FakeLink";

import {
  faClock,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
const TopInfoSection = () => {
  return (
    <div className="section">
      <FakeLink text="Pon-Pet : 08 - 16 h" favIco={faClock}></FakeLink>
      <span className="devider"> | </span>
      <FakeLink
        text="062 / 87 14 363"
        toAction="tel:+381628714363"
        favIco={faPhone}
      ></FakeLink>
      <span className="devider"> | </span>
      <FakeLink
        text="alustillns@gmail.com"
        toAction="mailto:alustillns@gmail.com"
        favIco={faEnvelope}
      ></FakeLink>
    </div>
  );
};
export default TopInfoSection;
