import "./WhyUsSection.css";
import WhyUsPictureSection from "./whyUsPictureSection/WhyUsPictureSection";
import WhyUsTextSection from "./whyUsTextSection/WhyUsTextSection";

const WhyUsSection = () => {
  return (
    <div>
      <div className="why-us-section-container">
        <div className="why-us-section-container-inner sizer">
          <WhyUsTextSection />
          <div>
            <WhyUsPictureSection />
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhyUsSection;
