import "./OfferHeader.css";
import OfferFlag from "./offerFlag/OfferFlag";

const OfferHeader = () => {
  const items = [
    "moderan dizajn",
    "besplatno skidanje mera",
    "besplatne konsultacije",
    "besplatna demontaža stare stolarije",
    "doživotna mogućnost održavanja i servisiranja stolarije",
  ];
  return (
    <div className="offer-header-container">
      <div className="offer-header-inner sizer">
        <h1>Cenovnik</h1>
        <h5>
          Tačnu ponudu za stolariju vašeg doma možete dobiti u samo par klikova
          ukoliko odaberete vrstu stolarije i dalje uneste željene pozicije.
        </h5>
        <div className="offer-flag-list">
          <OfferFlag
            buttonName="Započni"
            price={40}
            title="PVC Stolarija"
            items={items}
            text="Stolarija izrađena od nemačkih Salamander profila i Siegenia okova, sa kvalitetnim italijanskim saklom ispunjenim argonom"
          />
          <OfferFlag
            inverted={true}
            buttonName="Započni"
            price={70}
            title="Aluminijumska Stolarija"
            items={items}
            text="Stolarija izrađena od grčkih Elvial ili Alumil profila i Siegenia okova, sa Kvalitetnim italijanskim staklom ispunjenim argonom"
          />
          <OfferFlag
            buttonName="Započni"
            price={10}
            title="Prateća oprema"
            items={items}
            text="Pod pratećom opremom smatraju se roletne komarnici, želuzine solomatici, klupice i ostalo"
          />
        </div>
      </div>
    </div>
  );
};

export default OfferHeader;
