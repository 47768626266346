import Navigation from "./navigation/Navigation";
import TopHead from "./topHead/TopHead";
import "./Header.css";

interface IHeader {}
const Header = ({}: IHeader) => {
  return (
    <div className="head-container ">
      <TopHead />
      <Navigation />
    </div>
  );
};
export default Header;
