import { IWorker } from "../../../composit/worker/Worker";
const worker1 = require("../../../assets/worker1.jpg");
const worker2 = require("../../../assets/worker2.jpg");
const worker3 = require("../../../assets/worker3.jpg");

export interface IAboutVar {
  workers: IWorker[];
}
export const AboutVar: IAboutVar = {
  workers: [
    {
      imgName: "worker 1 ",
      imgSrc: worker1,
      name: "Jimmy Geovedi",
      role: "FOUNDER",
    },
    {
      imgName: "worker 2",
      imgSrc: worker2,
      name: "Rudolf Adler",
      role: "TECHNICIAN",
    },
    {
      imgName: "worker 3",
      imgSrc: worker3,
      name: "Raymond Cutts",
      role: "TECHNICIAN",
    },
    {
      imgName: "worker 1 ",
      imgSrc: worker1,
      name: "Jimmy Geovedi",
      role: "FOUNDER",
    },
    {
      imgName: "worker 2",
      imgSrc: worker2,
      name: "Rudolf Adler",
      role: "TECHNICIAN",
    },
    {
      imgName: "worker 3",
      imgSrc: worker3,
      name: "Raymond Cutts",
      role: "TECHNICIAN",
    },
  ],
};
