import Logo from "../../../common/logo/Logo";
import "./DescriptionSection.css";
const logo = require("../../../assets/Logo.png");
const DescriptionSection = () => {
  return (
    <div>
      <h3>Description</h3>
      <Logo imageName="Logo" imagePath={logo} />
      <p>Mi čuvamo toplinu vašeg doma</p>
    </div>
  );
};
export default DescriptionSection;
