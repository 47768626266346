import { faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import MediaLink from "../../../../common/link/mediaLink/MediaLink";

const TopMediaSection = () => {
  return (
    <div className="section section-right">
      <MediaLink icon={faFacebook} showWraper={true} />
      <MediaLink icon={faWhatsapp} showWraper={true} />
    </div>
  );
};
export default TopMediaSection;
