import ModalQuestion from "../modalQuestion/ModalQuestion";
import "./TextPoper.css";
import TextPoperItem from "./textPoperItem/TextPoperItem";

export interface IText {
  title: string;
  text: string;
}
export interface ITextPoper {
  items: IText[];
}
const TextPoper = ({ items }: ITextPoper) => {
  return (
    <div className="text-poper-container">
      <div className="text-poper-inner sizer">
        {items.map((item, index) => {
          return (
            <div className="text-poper-section ">
              <ModalQuestion
                text={item.text}
                question={item.title}
                title={item.title}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default TextPoper;
