import { useNavigate } from "react-router-dom";
import Button from "../../../../common/button/Button";
import "./OurProcessTextSection.css";

const OurProcessTextSection = () => {
  const navigate = useNavigate();

  const changeUrl = () => {
    // Use the navigate function to navigate to a new URL
    navigate("/contact");
    window.scrollTo(0, 0);
  };
  return (
    <div className="our-proces-text-section">
      <h5 className="red-header">Pogodnosti</h5>
      <h1>4 Jednostavna koraka do nove stolarije</h1>
      <h5>
        Zamenite stolariju kod nas i uživajte u toplini svog doma bez dodatnih
        troškova i odlaganja termina.
      </h5>
      <Button name="Kontaktirajte nas" action={changeUrl} />
    </div>
  );
};

export default OurProcessTextSection;
