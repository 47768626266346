import "./AboutMeTopper.css";

const AboutMeTopper = () => {
  return (
    <div className="about-me-topper">
      <div className="about-me-topper-container">
        <div className="about-me-topper-container-inner sizer">
          <h1>O nama</h1>
          <h5>
            Alu-Still je porodična kompanija zvanično osnovana 2004. i od tada
            broji hiljade zadovoljnih kupaca.
          </h5>
        </div>
      </div>
    </div>
  );
};
export default AboutMeTopper;
