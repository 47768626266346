import "./Footer.css";
import ContactInfoSection from "./contactInfoSection/ContactInfoSection";
import DescriptionSection from "./descriptionSection/DescriptionSection";
import QuickLinkSection from "./quickLinkSection/QuickLinkSection";
interface IFooter {}

const Footer = ({}: IFooter) => {
  return (
    <div className="footer-container">
      <div className="inner-footer-container sizer">
        <div>
          <DescriptionSection />
        </div>
        <div>
          <QuickLinkSection />
        </div>
        <div>
          <ContactInfoSection />
        </div>
      </div>
    </div>
  );
};

export default Footer;
