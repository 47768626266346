import ModalEmail from "../../../../composit/modalEmail/ModalEmail";
import "./OfferFlag.css";
interface IOfferFlag {
  title: string;
  text: string;
  price: number;
  items: string[];
  buttonName: string;
  inverted?: boolean;
}
const OfferFlag = ({
  title,
  text,
  price,
  items,
  buttonName,
  inverted = false,
}: IOfferFlag) => {
  const colour = inverted ? "blue-background" : "white-background";
  return (
    <div>
      <div className={`offer-flag-container ${colour}`}>
        <div className="offer-flag-inner">
          <div className="offer-flag-spearater">
            <h1>{title}</h1>
            <p>{text}</p>
          </div>
          <h2>od {price}€</h2>
          <ul>
            {items.map((i, index) => (
              <li key={index}>{i}</li>
            ))}
          </ul>

          <ModalEmail buttonName={buttonName} isInverted={inverted} />
        </div>
      </div>
    </div>
  );
};
export default OfferFlag;
