import Article from "../../../composit/article/Article";
import { SiteVars } from "../../../utils/SiteVars";
import Reveal from "../../../utils/reveal/Reveal";
import "./ArticlesSection.css";
const builder6 = require("../../../assets/builder6.jpg");
const builder1 = require("../../../assets/builder1-cut2.jpg");
const builder7 = require("../../../assets/builder7.jpg");

const ArticlesSection = () => {
  const { title, subTitle, articles } = SiteVars.articlePage;
  return (
    <div className="articles-section-container sizer">
      <div className="articles-section-container-top ">
        <div>
          <h5 className="red-header">{subTitle}</h5>
          <Reveal>
            <h1> {title}</h1>
          </Reveal>
        </div>
      </div>
      <div className="articles-section-container-bottom">
        {articles.map((x) => {
          return (
            <div>
              <Article
                imgSource={x.photoSrc}
                imgName={x.title}
                date="January 19, 2022 "
                tittle={x.title}
                text={x.text}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ArticlesSection;
