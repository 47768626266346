import Image from "../../../../common/image/Image";
import "./WhyUsPictureSection.css";
const builder3 = require("../../../../assets/builder3.jpg");
const builder4 = require("../../../../assets/builder4.jpg");
const builder5 = require("../../../../assets/builder5.jpg");

const WhyUsPictureSection = () => {
  return (
    <div>
      <div className="why-us-picture-section">
        <div className="why-us-picture-section-top">
          <Image source={builder3} altName="builder 3" />
        </div>
        <div className="why-us-picture-section-bottom">
          <Image source={builder4} altName="builder 4" />
          <Image source={builder5} altName="builder 5" />
        </div>
      </div>
    </div>
  );
};

export default WhyUsPictureSection;
