import ServicePresent from "../../../composit/servicePresent/ServicePresent";
import ServicePresentation from "../../../composit/servicePresentation/ServicePresentation";
import Reveal from "../../../utils/reveal/Reveal";
import Slide from "../../../utils/slide/Slide";
import "./WhyUsServices.css";
const checkImg = require("../../../assets/check.png");

const WhyUsServices = () => {
  return (
    <div>
      <div className="why-us-services-container sizer">
        <div className="why-us-services-inner">
          <Reveal>
            <h5 className="red-header">Pogodnosti</h5>
          </Reveal>

          <Reveal delay={0.7}>
            <h1>Zašto odabrati nas</h1>
          </Reveal>
          <div>
            <div className="why-us-services-present">
              <Slide delay={1}>
                <ServicePresent
                  title="Skidanje mera i savetovanje ulazi u cenu stolarije"
                  text="Našim kupcima nudimo mogucnost skidanja mera , savetovanje i pronalaženja optimalnog rešenja za izradu nove stolarije vašeg doma."
                />
              </Slide>
              <Slide delay={0.6}>
                <ServicePresent
                  title="Besplatna montaža nove stolarije"
                  text="Sva stolarija koju ugrađujemo se posebno izrađuje po meri i željama kupaca kod nas je montaža besplatna kako bi se kupcima obezbedio najbolji mogući kvalitet usluga."
                />
              </Slide>
              <Slide delay={0.3}>
                <ServicePresent
                  title="Besplatna demontaža stare stolarije"
                  text="Vršimo usluge demontaže Vaše postojeće stolarije i po potrebi odnošenje iste kako bismo naše kupce poštedeli dodatnih troškova. Za nama Vam ostaju samo molerski radovi, obrade spaleta."
                />
              </Slide>
              <Slide delay={0}>
                <ServicePresent
                  title="Doživotna mogućnost i servisiranje stolarije"
                  text="Kao porodična kompanija na tržištu smo već 20 godina i nameravamo da tu ostanemo. Shodno tome nasi kupci imaju mogućnost doživotnog održavanja stolarije."
                />
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhyUsServices;
