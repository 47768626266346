import "./Step.css";
interface IStep {
  orderNumber: string;
  title: string;
  text: string;
  isInverted?: boolean;
}
const Step = ({ orderNumber, title, text, isInverted = false }: IStep) => {
  return (
    <div className={isInverted ? "step-container inverted" : "step-container"}>
      <div className="step-order">{orderNumber}</div>
      <h3>{title}</h3>

      <div>
        <p>{text}</p>
      </div>
    </div>
  );
};
export default Step;
