import "./HomeHeadPictureSection.css";
import Image from "../../../../common/image/Image";
import CounterSection from "../../../../composit/counterSection/CounterSection";
import HeadWithComment from "./headWithComment/HeadWithComment";
import { SiteVars } from "../../../../utils/SiteVars";

const HomeHeadPictureSection = () => {
  const {
    topImg,
    topImgAltName,
    headImg,
    counterTitle,
    headComment,
    counterNumber,
  } = SiteVars.homehead;
  return (
    <div>
      <div className="picture-section">
        <div className="img-wraper">
          <Image altName={topImgAltName} source={topImg} />
        </div>
      </div>
      <div className="home-head-counter-section">
        <CounterSection num={counterNumber} text={counterTitle} />
      </div>
      <HeadWithComment comment={headComment} img={headImg} />
    </div>
  );
};

export default HomeHeadPictureSection;
