import { useRef, useState, useEffect } from "react";
import { IProp } from "../../utils/interface/IProp";
import "./Slider.css";
import { motion } from "framer-motion";
interface ISlider {
  sliders: JSX.Element[];
}

const Slider = ({ prop }: IProp<ISlider>) => {
  const { sliders } = prop;
  const [width, setWidth] = useState(0);
  const slider = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let scrollWidth = 0;
    if (slider.current?.scrollWidth) scrollWidth = slider.current?.scrollWidth;

    let offsetWidth = 0;
    if (slider.current?.offsetWidth) offsetWidth = slider.current?.offsetWidth;
    setWidth(scrollWidth - offsetWidth);
  }, []);
  return (
    <div className="sizer">
      <motion.div
        className="slider "
        ref={slider}
        whileTap={{ cursor: "grabbing" }}
      >
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          className="slider-inner"
        >
          {sliders.map((x, index) => (
            <motion.div className="item" key={`sl-${index}`}>
              {x}
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};
export default Slider;
