import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import AMediaLink from "../../common/link/awesomeMediaLink/AwesomeMediaLink";
import "./ServicePresentation.css";

interface IServicePresentation {
  title: string;
  text: string;
  imgSrc?: string;
  imgName?: string;
  faIco?: IconDefinition;
}
const ServicePresentation = ({
  imgSrc,
  imgName,
  title,
  text,
  faIco,
}: IServicePresentation) => {
  return (
    <div>
      <div className="service-presentation-container">
        {imgSrc && (
          <div className="service-presentation-img">
            <img src={imgSrc} alt={imgName} />
          </div>
        )}

        {faIco && (
          <div className="service-presentation-fa">
            <AMediaLink icon={faIco} />
          </div>
        )}
        <div className="service-presentation-text">
          <h3>{title}</h3>
          <div>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServicePresentation;
