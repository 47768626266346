import { SiteVars } from "../../utils/SiteVars";
import "./Contact.css";
import ContactToper from "./contactToper/ContactToper";
import OurOffice from "./ourOffice/OurOffice";
const Contact = () => {
  return (
    <div className="contact">
      <div className="contact-container">
        <div className="contact-container-inner ">
          <div className=" sizer">
            <ContactToper />
          </div>
          <div className="office-holder ">
            <OurOffice prop={SiteVars.firstLocation} />

            <OurOffice prop={SiteVars.secondLocation} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
