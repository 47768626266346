import "./Logo.css";
interface ILogo {
  imageName: string;
  imagePath?: string;
}
const Logo = ({ imagePath, imageName }: ILogo) => {
  //   const logo = myImage;
  return (
    <span className="logo-container">
      {imagePath && <img className="logo" src={imagePath} alt="Logo" />}
      {!imagePath && <h4>{imageName}</h4>}
    </span>
  );
};
export default Logo;
