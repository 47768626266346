import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import AMediaLink from "../../common/link/awesomeMediaLink/AwesomeMediaLink";
import "./ServicePresent.css";

interface IServicePresent {
  title: string;
  text: string;
}
const ServicePresent = ({ title, text }: IServicePresent) => {
  return (
    <div>
      <div className="service-present-container">
        <div className="service-present-inner">
          <div className="service-present-check">
            <AMediaLink icon={faCircleCheck} />
          </div>
          <h3>{title}</h3>
          <div>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServicePresent;
