import sendMail from "../../../../utils/mail/MailSender";
import "./ContactToperForm.css";
import { ChangeEvent, FormEvent, useRef, useState } from "react";

const ContactToperForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    message: "",
    phone: "",
  });
  const form = useRef<HTMLFormElement>(null);
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission
    // Call your function here, for example, sending an email
    sendMail(
      formData.name + " " + formData.surname,
      formData.email,
      formData.message,
      formData.phone
    );
    setFormData({
      name: "",
      surname: "",
      email: "",
      message: "",
      phone: "",
    });
  };
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <div>
      <div className="contact-toper-form-container">
        <div className="contact-toper-form-inner">
          <section>
            <form ref={form} onSubmit={handleSubmit}>
              <div className="input-section">
                <div>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Ime"
                    name="name"
                    required
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Prezime"
                    name="surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="E-Mail"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Telefon"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div>
                <textarea
                  placeholder="Vaša Poruka"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <button type="submit">Pošalji poruku</button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};
export default ContactToperForm;
