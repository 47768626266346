import CounterSection from "../../../composit/counterSection/CounterSection";
import Reveal from "../../../utils/reveal/Reveal";
import "./WhoAreWe.css";
const builder8 = require("../../../assets/builder8.jpg");
const WhoAreWe = () => {
  return (
    <div className="who-are-we">
      <div className="who-are-we-container">
        <div className="who-are-we-container-inner sizer">
          <div className="who-are-we-left">
            <div className="who-are-we-image">
              <img src={builder8} alt="builder" />
              <div className="who-are-we-counter">
                <CounterSection
                  text="Naš cilj:"
                  subtext="Kvalitetna usluga, brza ugradnja i zadovoljni klijent"
                  alignText={true}
                />
              </div>
            </div>
          </div>
          <div className="who-are-we-right">
            <Reveal>
              <h5 className="red-header">Ko smo mi</h5>
            </Reveal>
            <Reveal>
              <h1>
                Profesionalno i brzo proizvodimo ugrađujemo stolariju više od 20
                godina
              </h1>
            </Reveal>
            <Reveal>
              <h5>
                Naši kupci su uvek upućeni u kvalitet i poreklo ugradjenih
                materijala, dok se proizvodnja i ugradnja obavlja sa najvećom
                posvećenošću i pažnjom.
              </h5>
            </Reveal>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhoAreWe;
