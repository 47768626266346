import React, { ChangeEvent } from "react";
import "./Carpentry.css";
import Multiselect from "multiselect-react-dropdown";

interface SubComponentProps {
  index: number;
  carpentry: ICarpentry;
  removeCarpentry: (id: number) => void;
  updateCarpentry: (updatedCarpentry: ICarpentry) => void;
}
export interface ICarpentry {
  id: number;
  carpentryType: string;
  wingNumber: string;
  equipmentList: IEquipment[];
  amount: number;
  materialType: string;
  width: number;
  height: number;
  color: string;
}
interface IEquipment {
  name: string;
  id: number;
}
const Carpentry: React.FC<SubComponentProps> = ({
  index,
  carpentry,
  removeCarpentry,
  updateCarpentry,
}) => {
  const state = {
    options: [
      { name: "Roletne", id: 1 },
      { name: "Komarnik", id: 2 },
      { name: "Klupica", id: 3 },
      { name: "Okapnica", id: 4 },
    ],
  };
  const handleRemove = () => {
    removeCarpentry(carpentry.id);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    // Assuming carpentry object is immutable, create a new object with updated value
    const updatedCarpentry = { ...carpentry, [name]: value };
    // You can update state or use a callback to parent component to update the carpentry list
    updateCarpentry(updatedCarpentry);
  };

  const handleCarpentryTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = e.target;
    const updatedCarpentry = { ...carpentry, carpentryType: value };
    updateCarpentry(updatedCarpentry);
  };

  const handleWingNumberChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    const updatedCarpentry = { ...carpentry, wingNumber: value };
    updateCarpentry(updatedCarpentry);
  };
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    const updatedCarpentry = { ...carpentry, [name]: value };
    updateCarpentry(updatedCarpentry);
  };

  const onSelectMulti = (
    selectedList: IEquipment[],
    selectedItem: IEquipment
  ) => {
    debugger;
    const updatedCarpentry = {
      ...carpentry,
      equipmentList: [...carpentry.equipmentList, selectedItem],
    };
    updateCarpentry(updatedCarpentry);
  };

  const onRemoveMulti = (
    selectedList: IEquipment[],
    removedItem: IEquipment
  ) => {
    const updatedCarpentry = {
      ...carpentry,
      equipmentList: [
        ...carpentry.equipmentList.filter(
          (x: IEquipment) => x.id !== removedItem.id
        ),
      ],
    };

    updateCarpentry(updatedCarpentry);
  };

  const handleInChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedCarpentry = { ...carpentry, [name]: value };
    updateCarpentry(updatedCarpentry);
  };

  return (
    <div>
      {index > 0 && (
        <button className="remove-button" onClick={handleRemove}>
          Ukloni
        </button>
      )}
      <div className="input-group">
        <label className="label">Vrsta Stolarije:</label>
        <select
          name="carpentryType"
          value={carpentry.carpentryType}
          onChange={handleCarpentryTypeChange}
          className="select"
        >
          <option value="vrata">Vrata</option>
          <option value="prozor">Prozor</option>
          <option value="portal">Portal</option>
        </select>
      </div>
      <div className="input-group">
        <label className="label">Broj krila:</label>
        <select
          name="wingNumber"
          value={carpentry.wingNumber}
          onChange={handleWingNumberChange}
          className="select"
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
        </select>
        {/* Other input fields and select dropdowns */}
      </div>
      <div className="input-group">
        <label className="label">Dodatna oprema:</label>
        <Multiselect
          options={state.options} // Options to display in the dropdown
          selectedValues={carpentry.equipmentList} // Preselected value to persist in dropdown
          onSelect={onSelectMulti} // Function will trigger on select event
          onRemove={onRemoveMulti} // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
        />
      </div>
      <div className="input-group">
        <label className="label">Materijal Izrade:</label>
        <select
          name="carpentryType"
          value={carpentry.materialType}
          onChange={handleInputChange}
          className="select"
        >
          <option value="Aluminijum">Aluminijum</option>
          <option value="PVC">PVC</option>
        </select>
      </div>
      <div className="input-dimensions">
        <div className="input-group input-steep">
          <label>Širina</label>
          <input
            type="number"
            placeholder="širina"
            name="width"
            required
            onChange={handleInChange}
          />
        </div>
        <div className="input-group input-steep">
          <label>Visina</label>
          <input
            type="number"
            placeholder="visina"
            name="height"
            required
            onChange={handleInChange}
          />
        </div>
      </div>
      <div className="input-qunatity">
        <label>Količina</label>
        <input
          type="number"
          placeholder="Količina"
          name="quantity"
          required
          defaultValue={1}
          min={1}
          onChange={handleInChange}
        />
      </div>
      <div className="input-group">
        <label className="label">Boja:</label>
        <select
          name="color"
          value={carpentry.color}
          onChange={handleSelectChange}
          className="select"
        >
          <option value="Belo">Belo</option>
          <option value="Antracit">Antracit</option>
          <option value="Braon">Braon</option>
          <option value="Drvo dekor">Drvo dekor</option>
        </select>
        {/* Other input fields and select dropdowns */}
      </div>
    </div>
  );
};

export default Carpentry;
